<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <vs-spacer />
        <div class="d-none">
          <vs-icon icon="user" />
          <div class="user-nav flex flex-col">
            <div class="user-name text-bold-600">USER</div>
            <div class="user-status text-capitalize">PROFILE</div>
          </div>
        </div>
        <vs-dropdown>
          <a class="flex items-end flex-row" href="#">
            <img
              class="country-flag"
              :src="`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${currentLang.flag}.svg`"
              style="
                display: inline-block;
                width: 1em;
                height: 1em;
                vertical-align: middle;
              "
            />
            {{ currentLang.name }}
          </a>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="lang in languagesAvailable"
              :key="lang.iso"
            >
              <img
                class="country-flag"
                :src="`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${lang.flag}.svg`"
                style="
                  display: inline-block;
                  width: 1em;
                  height: 1em;
                  vertical-align: middle;
                "
              />
              {{ lang.name }}
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <!-- <vs-dropdown> -->
        <a class="flex items-center" href="#">
          <vs-icon icon="globe" />
        </a>
        <!-- <vs-dropdown-menu>
            <vs-dropdown-item v-for="lang in languages" :key="lang.iso">
              <img
                class="country-flag"
                :src="`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${lang.flag}.svg`"
                style="
                  display: inline-block;
                  width: 1em;
                  height: 1em;
                  vertical-align: middle;
                "
              />
              {{ $t(`language.${lang.iso}`) }}</vs-dropdown-item
            >
          </vs-dropdown-menu>
        </vs-dropdown> -->
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Bookmarks from './components/Bookmarks.vue';
// import SearchBar from './components/SearchBar.vue';
// import NotificationDropDown from './components/NotificationDropDown.vue';
// import ProfileDropDown from './components/ProfileDropDown.vue';

export default {
  name: 'TheNavbarVertical',
  components: {
    //
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      languages: [
        {flag: 'es', iso: 'es', name: 'Español'},
        {flag: 'us', iso: 'en', name: 'English'},
        {flag: 'br', iso: 'pt', name: 'Português'},
      ]
    }
  },
  computed: {
    ...mapState({"user" : "user/info"}),
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return { 'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark') };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == 'default') return 'navbar-default';
      if (this.verticalNavMenuWidth == 'reduced') return 'navbar-reduced';
      if (this.verticalNavMenuWidth) return 'navbar-full';
    },
    currentLang() {
      const locale = this.$i18n.locale
      return this.languages.filter(function(l){ l.iso === locale })
    },
    languagesAvailable() {
      const locale = this.$i18n.locale
      return this.languages.filter(function(l){ l.iso !== locale })
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>
