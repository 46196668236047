export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'menu.home',
  },
  {
    url: '/',
    name: 'Campaigns',
    slug: 'campaigns',
    icon: 'MapPinIcon',
    i18n: 'menu.campaigns',
  },
];
